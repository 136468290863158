body {
    background-size: cover;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    background: transparent linear-gradient( 243deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box;
}

.navbar-dark .navbar-nav .nav-link {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
}

.swal2-styled.swal2-confirm.twitter-share-btn {
    font-weight: 600;
    color: #fff !important;
    background: #60a5fa !important;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    background: #2c2d30;
}

nav>div {
    display: flex;
}

nav>div>button {
    margin-left: 1rem;
}

@media (max-width: 400px) {
    nav>h1 {
        max-width: 90px;
    }
}

h2 {
    padding: 0 15vw;
}

.trans {
    margin-top: 10px;
    display: block;
    background: #0b0f0f5c;
    border: black;
    border-radius: 10px;
    height: 30px;
    text-align: center;
    color: #f27720;
}

.trans-success {
    margin-top: 10px;
    display: block;
    background: #0b0f0f5c;
    border: black;
    border-radius: 10px;
    text-align: center;
    color: #8bc34a;
    font-weight: 600;
    padding: 15px;
}

.connect-wallet {
    background-color: #4e44ce;
}

.wallet-adapter-button,
.wallet-adapter-modal-wrapper {
    font: inherit;
}


/*  {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(14vw, 1fr));
gap: 2rem;

position: relative;
/* max-width: 70vw;
padding: 0 15vw; 
} */

.card {
    display: inline-grid;
    place-content: center;
    gap: 0.25rem;
    padding: 15px;
    color: antiquewhite;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    opacity: 1;
    text-align: center;
}

.card img {
    max-width: 13rem;
    border-radius: 17px;
}

.card button {
    display: inline-block;
    margin: 0.1rem;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    background-color: #fff2;
    backdrop-filter: blur(8px);
    border: 0.25px solid #fff4;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
}

.counter {
    font-variant-numeric: tabular-nums;
}


/* Css For Navigation Page */

@media only screen and (min-width: 981px) {
    .logo img {
        width: 150px;
    }
}

@media only screen and (max-width: 981px) {
    .logo img {
        width: 130px;
    }
    .headline {
        font-size: 1.5em !important;
    }
    .sub-heading {
        width: 100% !important;
    }
    .header-menu-container {
        padding: 7px 0px 5px 0px !important;
    }
    nav {
        padding: 0 1rem;
    }
    .nav-social {
        display: inline-flex;
    }
    .nav-social-container {
        display: block;
        text-align: center;
    }
    .sub-container {
        padding: 0px 0px !important;
    }
    .countStyle {
        width: 100% !important;
    }
    .text-left {
        text-align: center !important;
    }
    .count-col-text {
        font-size: 15px !important;
    }
    .info-sub-heading,
    .sub-heading-1 {
        font-size: 18px !important;
    }
    .color-white {
        font-size: 14px !important;
    }
    .c-heading {
        font-size: 23px !important;
    }
}

.headline {
    font-size: 40px;
    color: #fff;
    letter-spacing: 0.8px;
    font-weight: 600;
}

.sub-heading {
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.32px;
    padding-top: 15px;
    width: 66%;
    margin: auto;
    line-height: 30px;
}

.brand {
    width: 200px;
    border-radius: 25px;
    box-shadow: 0px 10px 45px #1e439580;
}

.withdraw {
    display: inline-block;
    margin: 0.1rem;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    background-color: #fff2;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 0.25px solid #fff4;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
}

.nft-nameStaked,
.nft-nameUnstaked {
    /* color: black; */
    color: #231F20;
    font-size: 18px;
    letter-spacing: 0.36px;
    margin-top: 12px;
    opacity: 1;
    text-align: center;
    max-width: unset;
    margin: unset;
    padding-top: 5px;
    padding-bottom: 5px;
    /* font-weight: 600; */
}

.stake {
    background-color: unset !important;
    background: transparent linear-gradient( 270deg, #2898D5 0%, #1D4496 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    color: #fff !important;
    white-space: nowrap !important;
    text-align: center !important;
    border: unset;
    width: 100%;
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
}

.upgrade {
    background-color: unset !important;
    background: #ffc107 !important;
    border-radius: 10px !important;
    color: #160f1e !important;
    white-space: nowrap !important;
    text-align: center !important;
    border: unset;
    width: 100%;
    /* box-shadow: 0 0 10px #212529; */
    text-transform: capitalize !important;
}

.nft-img {}

.card-body {
    padding: unset;
}

.c-heading {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    opacity: 1;
    letter-spacing: 0.6px;
    padding-left: 25px;
    border-left: 5px #ffffff solid;
}

.column {
    float: left;
    width: 50%;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
}


/* Clear floats after the columns */

.row:after {
    content: "";
    display: table;
    clear: both;
}

.info-sub-heading {
    font-size: 20px;
}

.color-white {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.32px;
}

.countStyle {
    /* border: 3px solid;
border-radius: 20px; */
    /* height: 50px; */
    font-size: xx-large;
    border: 2px dashed #ffffff80;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    margin: auto
}

.countStyle img {
    background: #4596CE;
    padding: 14px;
    border-radius: 10px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(120deg, #0596cc, #1e4395);
    z-index: 10;
}

.headerFont {
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 38px;
    margin: 0 8.5px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.headerFont:hover {
    color: #fff !important;
}

.btn {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 46px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    color: #1e4395;
    font-weight: 800 !important;
    min-width: 140px;
    max-width: 100%;
    border: none;
    box-sizing: border-box;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    border-radius: 40px;
    background: white;
}

.sub-container {
    padding: 0px 46px;
    /* background: #1e4395; */
    max-width: 1100px;
    margin: auto;
}

.rewards {
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    letter-spacing: 0.32px;
    color: #1e4395;
}

.sub-heading-1 {
    font-size: 20px;
    letter-spacing: 0.4px;
    color: #fff;
}

.home-sellect-wallet-btn {
    background: unset;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    padding: 10px 35px;
}

.home-sellect-wallet-btn:hover,
.disconnect-btn:hover {
    color: #fff;
}

.text-left {
    text-align: left;
}

.count-col-right {
    padding-left: 20px;
}

.count-col-text {
    font-size: 18px;
    letter-spacing: 0.36px;
    color: #BBCCE5;
    font-weight: 500;
}

.disconnect-btn {
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    background: unset;
    color: #fff;
    letter-spacing: 0.32px;
    font-size: 16px;
    text-transform: capitalize;
}

.horizontal-line {
    color: #1d469778;
    width: 90%;
    margin: auto;
}

.header-menu-container {
    max-width: 100%;
    padding: 7px 30px 5px 30px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #181c32a3;
    z-index: 99999;
}

.inner-loading-spinner {
    margin: auto;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #383636;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.swal2-popup {
    width: 30em !important;
}

.swal2-styled.swal2-confirm {
    border: 1px solid !important;
    background: unset !important;
    color: #6c757d !important;
}

.swal2-html-container {
    color: #1e4395 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px #f8f9fa !important;
}

.sub-para {
    color: #fff;
}

.font-w-600 {
    font-weight: 600;
}

.full-height {
    height: 100vh;
}

.in-misssion {
    position: absolute;
    top: 21px;
    right: 20px;
    font-weight: 600;
    height: 30px;
    background: #ffc107ad;
    color: #fff;
    padding: 5px;
    font-size: 14px;
    border-radius: 30px;
    border: 1px solid #ffffff61;
}

.swal2-styled.swal2-cancel.cancel-btn {
    background-color: #e9ecef;
    color: #333;
}

.swal2-styled.swal2-confirm.confirm-btn {
    font-weight: 600;
    background: #ffc107 !important;
    color: #160f1e !important;
}